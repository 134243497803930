import { createModule, mutation } from 'vuex-class-component';

const defaultTimeout = 10000;

const VuexModule = createModule({
  namespaced: 'snackbar',
});

export default class Snackbar extends VuexModule {
  public message = '';
  public color = 'info';
  public isOpen = false;
  public timeout = 10000;

  @mutation
  public showError(message: string) {
    this.isOpen = false;
    this.color = 'error';
    this.message = message;
    this.timeout = -1;
    this.isOpen = true;
  }

  @mutation
  public showWarning(message: string) {
    this.isOpen = false;
    this.color = 'warning';
    this.message = message;
    this.timeout = defaultTimeout;
    this.isOpen = true;
  }

  @mutation
  public showSuccess(message: string) {
    this.isOpen = false;
    this.color = 'success';
    this.message = message;
    this.timeout = defaultTimeout;
    this.isOpen = true;
  }

  @mutation
  public showInfo(message: string) {
    this.isOpen = false;
    this.color = 'info';
    this.message = message;
    this.timeout = defaultTimeout;
    this.isOpen = true;
  }
}
