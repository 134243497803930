import firebase from 'firebase/app';
import 'firebase/auth';

if (process.env.VUE_APP_FIREBASE_CONFIG === undefined) {
  throw new Error('No firebase config loaded through process.env');
}

// Initialize Firebase
const app = firebase.initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG));
const auth = app.auth();

const googleProvider = new firebase.auth.GoogleAuthProvider();
export {
  app, auth, googleProvider, firebase
};
