

















import { Vue, Component } from 'vue-property-decorator';
import { proxies } from '@/store';

import { mdiWhiteBalanceSunny } from '@mdi/js'

@Component
export default class SwitchDarkMode extends Vue {
  private theme = proxies.Theme;
  private mdiWhiteBalanceSunny = mdiWhiteBalanceSunny;
}
