













import { Vue, Component, Prop } from 'vue-property-decorator';
import { schema, queries } from '@/gql';
import sourceTypeMdi from '@/common/sourceTypeMdi';

interface DisplayName {
  channelDisplayName: string;
  sourceDisplayName: string;
  sourceType: schema.SourceType;
}

@Component({
  apollo: {
    displayNames: {
      fetchPolicy: 'cache-first',
      query: queries.channelBreadcrumbs,
      variables(): schema.QueryChannelArgs {
        return {
          uid: this.channelUid,
        };
      },
      skip() {
        return !this.channelUid;
      },
      update(query: schema.Query) {
        this.orgUid = query.channel?.source?.orgUid;
        return {
          channelDisplayName: query.channel?.displayName || 'Unknown',
          sourceDisplayName: query.channel?.source?.aspect?.displayName || 'Unknown',
          sourceType: query.channel?.source?.sourceType,
        };
      },
    },
    orgDisplayName: {
      query: queries.org,
      fetchPolicy: 'cache-first',
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {uid: this.orgUid};
      },
      update(query: schema.Query) {
        return query.org.displayName;
      },
    },
  }
})
export default class ChannelBreadcrumbs extends Vue {
  @Prop(String) private readonly channelUid!: string;
  private displayNames!: DisplayName;
  private orgUid!: string;
  private orgDisplayName: string = "";

  private get items() {
    if (this.orgUid && this.orgDisplayName) {
      return [
        {
          text: this.orgDisplayName,
        },
        {
          icon: sourceTypeMdi(this.displayNames.sourceType),
          text: this.displayNames.sourceDisplayName,
        },
        {
          text: this.displayNames.channelDisplayName
        },
      ];
    } else {
      return [];
    }
  }


}
