









import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs, { Dayjs } from '@/common/dayjs';

@Component
export default class DeltaTimeTooltip extends Vue {
  @Prop([Date, String, Number, Dayjs]) readonly datetime!: Date | string | number | Dayjs;
  @Prop([Date, String, Number, Dayjs]) readonly targetDatetime!: Date | string | number | Dayjs;

  private get readableDelta(): string {
    const fromDate = dayjs(this.targetDatetime);
    const toDate = dayjs(this.datetime)

    if (toDate.isSame(fromDate)) {
      return toDate.fromNow()
    } else if(toDate.isBefore(fromDate)) {
      return `${toDate.from(fromDate, true)} earlier`;
    } else {
      return `${toDate.from(fromDate, true)} later`;
    }
  }

  private get formattedDate(): string {
    return dayjs(this.datetime).format('lll');
  }
}
