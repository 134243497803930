import Vue from 'vue';

import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
// import { auth } from '@/plugins/auth';
import * as firebase from '@/plugins/firebase';
import ApolloClient, { gql } from 'apollo-boost';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { typeDefs } from '../gql/local';

export const cache = new InMemoryCache();

persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
} as any);

export const apolloClient = new ApolloClient({
  typeDefs,
  cache,
  resolvers: {},
  uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
  request: async (operation) => {
    if(firebase.auth.currentUser){
      const token  = await firebase.auth.currentUser.getIdToken();
      operation.setContext({
        headers: {
          Authorization: token ? `${token}` : '',
        },
      });
    }
  },
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export { apolloProvider };
