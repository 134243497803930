import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#5AA02C",
        secondary: "#2e55a4",
        success: "#5AA02C",
        info: "#2e55a4",
      },
      dark: {
        primary: "#5AA02C",
        secondary: "#6598ff",
        success: "#5AA02C",
        info: "#6598ff",
      }
    }
  }
});
