import { createModule, mutation, action } from 'vuex-class-component';
import { firebase, auth } from '@/plugins/firebase';
import { proxies } from '@/store';
import { cache, apolloClient  } from '@/plugins/apollo';
import axios from 'axios';

const VuexModule = createModule({
  namespaced: 'user',
});

interface EmailAndPassword {
  email: string
  password: string
}

interface PasswordChange {
  oldPassword: string
  newPassword: string
}


export default class User extends VuexModule {
  public static arkiveAuthPath = process.env.VUE_APP_OAUTH_ENDPOINT; // 'http://localhost:5000';

  private _uid = '';
  private _token = '';

  public get uid() { return this._uid; }
  public get isAuthenticated() { return !!this._uid; }
  public get token() { return this._token; }

  @mutation private setUid(uid: string) { this._uid = uid; }
  @mutation private setToken(token: string) { this._token = token; }

  @action
  public async doLogin(firebaseUser: firebase.User) {
    this.setUid(firebaseUser.uid);
    this.setToken(await firebaseUser.getIdToken())
    // proxies.Snackbar.showSuccess('Logged in!');
  }

  @action
  public async refreshLogin() {
    if (auth.currentUser) {
      this.setToken(await auth.currentUser.getIdToken())
      return true;
    }
    return false;
  }

  @action
  public async doLogout() {
    this.setUid('');
    this.setToken('');
    await auth.signOut();
    cache.reset();
    apolloClient.clearStore();
    localStorage.clear();
    proxies.Snackbar.showSuccess('You have been logged out!');
  }

  @action
  public async signInWithOauthToken(token: string): Promise<boolean> {
    const userCredential = await auth.signInWithCustomToken(token);
    if (userCredential.user) {
      await this.doLogin(userCredential.user);
      return true;
    } else {
      proxies.Snackbar.showError('Could not log in, invalid user');
      return false;
    }
  }
  
  @action
  public async signInWithEmailAndPassword(payload: EmailAndPassword): Promise<boolean> {
    const userCredential = await auth.signInWithEmailAndPassword(payload.email, payload.password);
    if (userCredential.user) {
      await this.doLogin(userCredential.user)
      return true;
    } else {
      proxies.Snackbar.showError('Could not log in, invalid user');
      return false
    }
  }

  @action
  public async deleteAccount(): Promise<boolean> {
    const result = await axios.delete(`${User.arkiveAuthPath}/auth/delete-account`, {
      headers: {
        Authorization: this.token,
      },
    });
    if (result.data.success) {
      await this.doLogout();
      proxies.Snackbar.showSuccess('Your account was successfully deleted');
      return true;
    } else {
      proxies.Snackbar.showError('There was a problem when deleting your account');
      return false;
    }
  }

  @action
  public async createAccount(payload: EmailAndPassword): Promise<boolean> {
    const result = await axios.post(
      `${User.arkiveAuthPath}/auth/create-account`,
      {
        email: payload.email,
        passworD: payload.password,
        displayName: payload.email,
      },
      {
        headers: {
          Authorization: this.token || '',
        },
      }
    );
    if (result.data.success) {
      await this.signInWithEmailAndPassword(payload);
      proxies.Snackbar.showSuccess('Your account was successfully created');
      return true;
    } else {
      proxies.Snackbar.showError('There was a problem creating the account');
      return false;
    }
  }

  @action
  public async changePassword(payload: PasswordChange) {
    if (!auth.currentUser) {
      proxies.Snackbar.showError('Could not change password, invalid user');
      return false;
    }
    const userCredential = await auth.signInWithEmailAndPassword(auth.currentUser.email as string, payload.oldPassword);
    if (userCredential.user) {
      await auth.currentUser.updatePassword(payload.newPassword);
      return true;
    } else {
      proxies.Snackbar.showError('There was a problem changing password');
      return false;
    }
  }
}
