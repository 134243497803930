import { RouteConfig } from 'vue-router';

export default [
  {
    path: '/org/:orgUidOrDomain',
    name: 'org',
    component: () => import(/* webpackChunkName: "community" */ '@/views/org/index.vue'),
    redirect: { name: 'dashboard' },
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '/org/:orgUidOrDomain/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "community" */ '@/views/org/dashboard/index.vue'),
        meta: {title: 'Dashboard'},
      },
      {
        path: '/org/:orgUidOrDomain/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "community" */ '@/views/org/settings/index.vue'),
        meta: {title: 'Settings'},
      },
      {
        path: '/org/:orgUidOrDomain/explore',
        name: 'explore',
        redirect: { name: 'search' },
      },
      {
        path: '/org/:orgUidOrDomain/recent',
        name: 'recent',
        component: () => import(/* webpackChunkName: "community" */ '@/views/org/explore/Recent/index.vue'),
        meta: {title: 'Recent Questions'},
      },
      {
        path: '/org/:orgUidOrDomain/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "community" */ '@/views/org/explore/Search/index.vue'),
        meta: {title: 'Search'},
      },
      {
        path: '/org/:orgUidOrDomain/knowledgebase',
        name: 'knowledgebase',
        component: () => import(/* webpackChunkName: "community" */ '@/views/org/knowledge/KnowledgeBase/index.vue'),
        meta: {title: 'Knowledgebase'},
      },
      {
        path: '/org/:orgUidOrDomain/knowledgebase/:messageEid',
        name: 'article',
        component: () => import(/* webpackChunkName: "explorer" */ '@/views/org/knowledge/Article/index.vue'),
        meta: {title: 'Article'},
      },
    //   /**
    //    * Explorer RAQ
    //    */
    //   {
    //     path: ':orgUid/recently-asked-questions',
    //     name: 'explorer-recent-questions',
    //     component: () => import(/* webpackChunkName: "explorer" */ '@/views/ExplorerRecentQuestions/ExplorerRecentQuestionsView.vue'),
    //   },
    //   /**
    //    * Explorer FAQ
    //    */
    //   {
    //     path: ':orgUid/frequently-asked-question',
    //     name: 'explorer-faq',
    //     component: () => import(/* webpackChunkName: "explorer" */ '@/views/ExplorerFaq/ExplorerFaqView.vue'),
    //   },
    //   /**
    //    * Explorer Search
    //    */
    //   {
    //     path: ':orgUid/explorer',
    //     name: 'explorer-search',
    //     component: () => import(/* webpackChunkName: "explorer" */ '@/views/ExplorerSearch/ExplorerSearchView.vue'),
    //   },
    //   /**
    //    * Explorer Wiki
    //    */
    //   {
    //     path: ':orgUid/topics',
    //     name: 'explorer-topics',
    //     component: () => import(/* webpackChunkName: "explorer" */ '@/views/ExplorerTopics/ExplorerTopicsView.vue'),
    //   },
      // ASK SESSION
      // {
      //   path: 'question/:sessionId',
      //   name: 'ask-session',
      //   component: () => import(/* webpackChunkName: "secured" */ '../../views/Secured/AskSession/View/index.vue'),
      // },
      // {
      //   path: 'question/:sessionId/edit',
      //   name: 'ask-session-edit',
      //   component: () => import(/* webpackChunkName: "secured" */ '../../views/Secured/AskSession/Edit/index.vue'),
      // },
      // {
    ],
  },
] as RouteConfig[];
