





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { proxies } from '@/store';
import AccountMenu from './AccountMenu.vue';
import OrgMenu from './OrgMenu.vue';

@Component({
  components: {
    AccountMenu,
    OrgMenu,
  }
})
export default class AppBar extends Vue {
  @Prop(String) private readonly orgUid!: string;

  private theme = proxies.Theme;
  private user = proxies.User;

  private history = proxies.History;
  private guide = proxies.Guide;
  private compose = proxies.Compose;

  private get expand() {
    const leftDrawerOpen = !!this.history.open;
    const rightDrawerOpen = !!this.guide.open || !!this.compose.open;

    if (leftDrawerOpen && rightDrawerOpen) {
      return this.$vuetify.breakpoint.xlOnly;
    }

    if (leftDrawerOpen != rightDrawerOpen) {
      return this.$vuetify.breakpoint.lgAndUp;
    }

    return this.$vuetify.breakpoint.smAndUp;
  }
};
