















































import { Vue, Component, Watch} from 'vue-property-decorator';
import { schema } from '@/gql';
import { proxies } from '@/store';
import HeadingCard from '@/components/section/HeadingCard.vue';
import ChannelBreadcrumbs from '@/components/output/ChannelBreadcrumbs.vue';
import MessageHistoryItem from '@/components/output/MessageHistoryItem.vue';
import { gql } from 'apollo-boost';

interface HistoryItems {
  message: schema.AspectMessage
  label: string
  id: string | false
  barColor: string
}

@Component({
  components: {
    HeadingCard,
    ChannelBreadcrumbs,
    MessageHistoryItem,
  },
  apollo: {
    history: {
      query: gql`
        query explorerMessageHistoryResultsHistory($channelUid: String!, $before: Float, $after: Float, $limit: Float!) {
          aspectMessageHistory(channelUid: $channelUid, before: $before, after: $after, limit: $limit) {
            pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                eid
                authorMemberEid
                text
                externalUrl
                sourceType
                timestamp
                channelUid
                sourceUid
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.channelUid || !this.timestamp;
      },
      variables(): schema.QueryAspectMessageHistoryArgs {
        return {
          channelUid: this.channelUid,
          before: this.timestamp,
          after: this.timestamp,
          limit: 10,
        };
      },
      update(query: schema.Query): schema.AspectMessage[] {
        if (this.loadedCorrelationId != this.historyState.correlationId) {
          this.loadedCorrelationId = this.historyState.correlationId;
          this.scrollToQuestion()
        }
        return query.aspectMessageHistory?.edges.map(edge => edge.node) || [];
      },
    },
  },
})
export default class HistoryDrawer extends Vue {
  private historyState = proxies.History;
  private history: schema.AspectMessage[] = [];
  private loadedCorrelationId:  string | null = null;

  private get historyItems(): HistoryItems[] {
    if (this.historyState.correlationId != this.loadedCorrelationId) {
      // hide history while loading
      return []
    }

    return this.history.map(message => {
      let label = '';
      let id: string | false = false;
      let barColor = '';
      if (message.eid == this.historyState.questionEid) {
        label = 'Detected Question';
        id = 'question';
        barColor = 'primary';
      } else if (this.historyState.answerEids.includes(message.eid)) {
        label = 'Detected Answer';
        id = 'answer';
        barColor = 'secondary';
      }

      return {
        message,
        label,
        id,
        barColor,
      }
    })
  }

  private get channelUid() {
    return this.historyState.channelUid
  }
  private get timestamp() {
    return this.historyState.questionTimestamp;
  }

  private get loading(): boolean {
    return this.historyState.correlationId != this.loadedCorrelationId && this.$apollo.queries.history.loading;
  }

  private scrollToQuestion() {
    this.$nextTick(() => {
      // one tick delay to ensure rendering
      const question = document?.getElementById('question');
      const container = document?.getElementById('history-drawer')?.firstChild;
      if (question && container) {
        this.$vuetify.goTo(question, {container: container as HTMLElement})
      }
    });
  }

  @Watch('historyState.open')
  private scrollOnOpen(value: boolean) {
    if (value && this.history.length) {
      this.scrollToQuestion()
    }
  }
};
