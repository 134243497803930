import { createModule, mutation } from 'vuex-class-component';

const VuexModule = createModule({
  namespaced: 'drawer',
});

interface DrawerPayload {
  channelUid: string
  questionEid: string
  questionTimestamp: number
  answerEids: string[]
  correlationId: string
}

export default class Drawer extends VuexModule {
  private _open: boolean = false;
  private _expand: boolean = false;
  private _drawerPayload: DrawerPayload | null = null;

  public get open(): boolean { return this._open; }
  public set open(value: boolean) { this._open = value; }
  public get expand(): boolean {
    return this._expand || !!localStorage.getItem('drawer.expand')
  }
  public set expand(value: boolean) {
    if (value) {
      localStorage.setItem('drawer.expand', 'true');
    } else {
      localStorage.removeItem('drawer.expand');
    }
    this._expand = value;
  }

  public get channelUid(): string | null {
    return this._drawerPayload?.channelUid || null;
  }
  public get questionEid(): string | null {
    return this._drawerPayload?.questionEid || null;
  }
  public get answerEids(): string[] {
    return this._drawerPayload?.answerEids || [];
  }
  public get questionTimestamp(): number | null {
    return this._drawerPayload?.questionTimestamp || null;
  }

  public get correlationId(): string | null {
    return this._drawerPayload?.correlationId || null;
  }

  @mutation
  public setPayload(payload: DrawerPayload) {
    this._drawerPayload = payload;
  }

  @mutation
  public openDrawerTo(payload: DrawerPayload) {
    this._drawerPayload = payload;
    this._open = true;
  }
}
