import { RouteConfig, Route } from 'vue-router';
import { proxies } from '@/store';

export default [
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: async (to: Route, from: Route, next: (route: { [key: string]: string }) => void) => {
      await proxies.User.doLogout();
      next({ name: 'login' });
    },
  },
  {
    path: '/login',
    name: 'login',
    component: (/* webpackChunkName: "public" */) => import('@/views/public/Login.vue'),
    meta: {
      title: 'Login',
      requireAnon: true,
    }
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: (/* webpackChunkName: "public" */) => import('@/views/public/NotFound.vue'),
    meta: {
      title: 'Not Found',
    }
  },
] as RouteConfig[];
