












































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { queries, schema } from '@/gql';
import { proxies } from '@/store';
import { mdiChevronDown } from '@mdi/js'

interface MenuItems {
  text: string
  toName?: string
  onClick?: Function
  subMenu?: MenuItems[]
}

@Component({
  apollo: {
    org: {
      query: queries.org,
      fetchPolicy: 'cache-first',
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {
          uid: this.orgUid,
        };
      },
    },
  },
})
export default class OrgMenu extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private history = proxies.History;
  private guide = proxies.Guide;
  private compose = proxies.Compose;

  private theme = proxies.Theme;
  private org!: schema.Org
  private mdiChevronDown = mdiChevronDown;

  private get loading() {
    return this.$apollo.queries.org.loading;
  }

  private orgMeta: MenuItems[] = [
    { toName: 'settings', text: 'Community settings' },
    { toName: 'communities', text: 'Switch community' },
  ]

  private get orgPages(): MenuItems[] {
    let items: MenuItems[] = [
      { toName: 'dashboard', text: 'Dashboard' },
      {
        toName: 'explore',
        text: 'Explore',
        subMenu: [
          { toName: 'search', text: 'Search' },
          { toName: 'recent', text: 'Recent' },
        ]
      },
      {
        toName: 'knowledgebase',
        text: 'Knowledge',
        subMenu: [
          { toName: 'knowledgebase', text: 'Knowledge Base' },
        ]
      },
    ];

    if (this.org?.knowledgebase.authorizePost) {
      items[2].subMenu?.push({ text: 'Create New Article', onClick: () => { this.compose.open = true; } });
    }

    return items;
  }

  private get dropMenuItems(): MenuItems[] {
    if (this.expand) {
      return this.orgMeta;
    } else {
      let items = [];
      for(const menu of this.orgPages) {
        if (!!menu.subMenu) {
          items.push(...menu.subMenu);
        } else {
          items.push(menu);
        }
      }
      return items.concat(this.orgMeta);
    }
  }

  private get topMenuItems(): MenuItems[] {
    if (this.expand) {
      return this.orgPages;
    } else {
      return [];
    }
  }

  private get expand() {
    const leftDrawerOpen = !!this.history.open;
    const rightDrawerOpen = !!this.guide.open || !!this.compose.open;
    
    if (leftDrawerOpen && rightDrawerOpen) {
      return false;
    }

    if (leftDrawerOpen != rightDrawerOpen) {
      return this.$vuetify.breakpoint.xlOnly;
    }

    return this.$vuetify.breakpoint.mdAndUp;
  }

};
