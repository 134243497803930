/* eslint-disable no-underscore-dangle */
import { createModule } from 'vuex-class-component';
import { schema } from '../gql';

const VuexModule = createModule({
  namespaced: 'theme',
});

export default class Theme extends VuexModule {
  private _darkMode: boolean | null = null;

  public get darkMode(): boolean {
    return this._darkMode || !!localStorage.getItem('theme.dark')
  }

  public set darkMode(value: boolean) {
    if (value) {
      localStorage.setItem('theme.dark', 'true');
    } else {
      localStorage.removeItem('theme.dark');
    }
    this._darkMode = value;
  }
}
