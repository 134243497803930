

















































import { Vue, Component } from 'vue-property-decorator';
import { mdiAccount, mdiChevronDown } from '@mdi/js'
import { proxies } from '@/store';

@Component
export default class AccountMenu extends Vue {
  private user = proxies.User;
  private mdiAccount = mdiAccount;
  private mdiChevronDown = mdiChevronDown;

  private history = proxies.History;
  private guide = proxies.Guide;
  private compose = proxies.Compose;

  private get expand() {
    const leftDrawerOpen = !!this.history.open;
    const rightDrawerOpen = !!this.guide.open || !!this.compose.open;

    if (leftDrawerOpen && rightDrawerOpen) {
      return this.$vuetify.breakpoint.xlOnly;
    }

    if (leftDrawerOpen != rightDrawerOpen) {
      return this.$vuetify.breakpoint.lgAndUp;
    }

    return this.$vuetify.breakpoint.smAndUp;
  }
};
