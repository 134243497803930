import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { proxies } from '@/store';

import pub from '@/router/public';
import org from '@/router/org';
import authenticated from '@/router/authenticated';
import oauth from '@/router/oauth';

Vue.use(VueRouter);

const routes = [
  ...pub,
  ...oauth,
  ...org,
  ...authenticated,
  {
    path: '/',
    name: 'home',
    beforeEnter: async (to: Route, from: Route, next: (route: { [key: string]: string }) => void) => {
      next({ name: proxies.User.isAuthenticated ? 'communities' : 'login' });
    },
  },
  {
    path: '*',
    name: 'redirect-all',
    redirect: { name: 'not-found' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.requireAuth);
  const requireAnon = to.matched.some((record) => record.meta.requireAnon);

  if (requireAnon && proxies.User.isAuthenticated) {
    next({ name: 'communities' });
    return;
  }

  if (requireAuth && !proxies.User.isAuthenticated) {
    next({ name: 'login', query: { from: to.fullPath === '/logout' ? undefined : to.fullPath } });
    return;
  }

  next();
});

export default router;
