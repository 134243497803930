import { RouteConfig } from 'vue-router';

export default [
  {
    path: '/communities',
    name: 'communities',
    component: (/* webpackChunkName: "authenticated" */) => import('@/views/communities/index.vue'),
    meta: {
      title: 'Communities',
      requireAuth: true,
    }
  },
  {
    path: '/account',
    name: 'account',
    component: (/* webpackChunkName: "authenticated" */) => import('@/views/account/index.vue'),
    meta: {
      title: 'Account',
      requireAuth: true,
    }
  },
] as RouteConfig[];
