import { schema } from '@/gql';
import { mdiDiscord, mdiBookOpen, mdiWeb } from '@mdi/js';

export default function(sourceType: schema.SourceType): string {
  switch(sourceType) {
    case schema.SourceType.Discord:
      return mdiDiscord;
    case schema.SourceType.Web:
      return mdiBookOpen;
    default:
      return mdiWeb;
  }
}