






















import { Vue, Component } from 'vue-property-decorator';
import { proxies, Snackbar } from '@/store';

@Component
export default class SnackbarComponent extends Vue {
  private snackbar: Snackbar = proxies.Snackbar;
}
