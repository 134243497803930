






























































import { Vue, Component, Prop } from 'vue-property-decorator';
import SwitchDarkMode from "@/components/layout/SwitchDarkMode.vue"
import { queries, schema } from '@/gql';
import { proxies } from '@/store';
import { mdiTwitter } from '@mdi/js';
import { VERSION } from '@/common/version';

interface MenuItems {
  text: string
  toName?: string
  onClick?: Function
  subMenu?: MenuItems[]
}

interface SocialData {
  aria: string
  icon: string
  link: string
}

@Component({
  components: {
    SwitchDarkMode
  },
  apollo: {
    org: {
      query: queries.org,
      fetchPolicy: 'cache-first',
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {
          uid: this.orgUid,
        };
      },
    },
  },
})
export default class AppFooter extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private org!: schema.Org
  private user = proxies.User;
  private guide = proxies.Guide;
  private compose = proxies.Compose;
  private version = VERSION;

  private linksPublic: MenuItems[] = [
    { text: "", subMenu: [] },
    { text: "", subMenu: [] },
    { text: "", subMenu: [] },
    {
      text: "Account",
      subMenu: [
        {text: "Log In", toName: "login" },
      ]
    },
  ];

  private get linksAuthenticated(): MenuItems[] {
    const items: MenuItems[] = [
      {
        text: "Home",
        subMenu: [
          {text: "Communities list", toName: "communities"},
        ]
      },
      { text: "", subMenu: [] },
      { text: "", subMenu: [] },
      {
        text: "Account",
        subMenu: [
          {text: "My account", toName: "account" },
          //{text: "Getting started", onClick: () => { this.guide.open = true; } },
          {text: "Log out", toName: "logout" },
        ]
      },
    ];

    if (this.orgUid) {
      items[0] = {
        text: "Community",
        subMenu: [
          {text: "Communities list", toName: "communities"},
          {text: "Dashboard", toName: "dashboard" },
          {text: "Settings", toName: "settings" },
        ]
      };
      items[1] = {
        text: "Explore",
        subMenu: [
          {text: "Search", toName: "search" },
          {text: "Recent Questions", toName: "recent" },
        ]
      };
      items[2] = {
        text: "Knowledge",
        subMenu: [
          {text: "Knowledge base", toName: "knowledgebase" },
        ]
      };

      if (this.org?.knowledgebase.authorizePost) {
        items[2].subMenu?.push({ text: 'Create New Article', onClick: () => { this.compose.open = true; } });
      }
    }

    return items
  }

  private socials: SocialData[] = [
    {
      aria: "Arkive on Twitter",
      icon: mdiTwitter,
      link: "https://twitter.com/ArkiveAI",
    }
  ];

  private get links(): MenuItems[] {
    return this.user.isAuthenticated? this.linksAuthenticated : this.linksPublic;
  }

  private get year(): number {
      return new Date().getFullYear();
  }
}
