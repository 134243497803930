import { createModule, mutation, action } from 'vuex-class-component';

const VuexModule = createModule({
  namespaced: 'guide',
});

export default class Guide extends VuexModule {
  private _open: boolean | null = null;
 
  public get open(): boolean | null { return this._open; }
  public set open(value: boolean | null) { this._open = value; }
}
