import Vue from 'vue';
import Vuex from 'vuex';
import { extractVuexModule, createProxy } from 'vuex-class-component';

import Compose from './Compose';
import Guide from './Guide';
import History from './History';
import Snackbar from './Snackbar';
import User from './User';
import Theme from './Theme';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    ...extractVuexModule(Compose),
    ...extractVuexModule(Guide),
    ...extractVuexModule(History),
    ...extractVuexModule(Snackbar),
    ...extractVuexModule(User),
    ...extractVuexModule(Theme),
  },
});

const proxies = {
  Compose: createProxy(store, Compose),
  Guide: createProxy(store, Guide),
  History: createProxy(store, History),
  Snackbar: createProxy(store, Snackbar),
  User: createProxy(store, User),
  Theme: createProxy(store, Theme),
};

export { store, proxies, Compose, Guide, History, Snackbar, User, Theme };
