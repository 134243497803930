
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { queries, schema } from '@/gql';
import sourceTypeMdi from '@/common/sourceTypeMdi';

@Component({
  apollo: {
    orgDisplayName: {
      query: queries.org,
      fetchPolicy: 'cache-first',
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {uid: this.orgUid};
      },
      update(query: schema.Query) {
        return query.org.displayName;
      },
    },
  },
})
export default class KnowledgeBreadcrumbs extends Vue {
  @Prop(String) private readonly orgUid!: string;
  private orgDisplayName: string | null = null;

  private get items() {
    if (this.orgUid && this.orgDisplayName) {
      return [
        {
          text: this.orgDisplayName,
        },
        {
          icon: sourceTypeMdi(schema.SourceType.Web),
          text: 'Knowledgebase',
          to: {name: 'knowledgebase', params: { orgUidOrDomain: this.orgUid }}
        },
      ];
    } else {
      return [];
    }
  }

}
