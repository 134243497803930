import { createModule, mutation, action } from 'vuex-class-component';

const VuexModule = createModule({
  namespaced: 'compose',
});

export default class Compose extends VuexModule {
  private _open: boolean | null = false;
  private _expand: boolean = false;
 
  public get open(): boolean | null { return this._open; }
  public set open(value: boolean | null) { this._open = value; }

  public get expand(): boolean {
    return this._expand || !!localStorage.getItem('compose.expand')
  }
  public set expand(value: boolean) {
    if (value) {
      localStorage.setItem('compose.expand', 'true');
    } else {
      localStorage.removeItem('compose.expand');
    }
    this._expand = value;
  }
}
