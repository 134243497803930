import * as schema from '@arkive-ai/client-gateway-gql/dist/schema';

// QUERIES
import channelBreadcrumbs from './queries/channelBreadcrumbs.gql';
import channelTree from './queries/channelTree.gql';

import me from './queries/me.gql';
import myUserAuths from './queries/myUserAuths.gql';
import myOrgs from './queries/myOrgs.gql';

import org from './queries/org.gql';

import recent from './queries/recent.gql';
import search from './queries/search.gql';

import sourceChannels from './queries/sourceChannels.gql';
import sourceConfig from './queries/sourceConfig.gql';
import tasks from './queries/tasks.gql';
import userSourceByUid from './queries/userSourceByUid.gql';

// MUTATIONS
import channelUpdate from './mutations/channelUpdate.gql'
import meUpdate from './mutations/meUpdate.gql';
import sourceUpdate from './mutations/sourceUpdate.gql'

export { schema };

export const queries = {
  channelBreadcrumbs,
  channelTree,
  me,
  myUserAuths,
  myOrgs,
  org,
  recent,
  search,
  sourceChannels,
  sourceConfig,
  tasks,
  userSourceByUid,
};

export const mutations = {
  channelUpdate,
  meUpdate,
  sourceUpdate,
};
