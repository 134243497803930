import { apolloProvider } from '@/plugins/apollo';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import { store } from './store';
import { auth, firebase } from './plugins/firebase'
import router from './plugins/router';
import Vuelidate from 'vuelidate'

// Vue.use(askSession);
Vue.use(Vuelidate)
Vue.config.productionTip = false;

import App from './App.vue';

const unsubscribe = auth.onAuthStateChanged(() => {
  new Vue({
    router,
    store,
    vuetify,
    apolloProvider,
    render: h => h(App),
    created () {
      auth.onAuthStateChanged((firebaseUser: firebase.User | null) => {
        if (firebaseUser && !store.getters['user/isAuthenticated']) {
          store.dispatch('user/doLogin', firebaseUser)
        }
      })
    }
  }).$mount('#app');
  unsubscribe();
})