



























import { Vue, Component } from 'vue-property-decorator';
import { queries, schema, mutations } from '@/gql';
import { proxies } from '@/store';
import HeadingCard from '@/components/section/HeadingCard.vue';
import { UserConfigModel } from '@arkive-ai/client-papi-rest';

@Component({
  components: {
    HeadingCard,
  },
  apollo: {
    me: {
      query: queries.me,
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.user.isAuthenticated;
      },
      update(query: schema.Query) {
        return query.me;
      },
    },
  },
})
export default class GuideDrawer extends Vue {
  private user = proxies.User;
  private guideState = proxies.Guide;
  private me!: schema.User;

  private get open(): boolean {
    // if (this.guide.open != null) return this.guide.open;

    // if (this.me?.configJson) {
    //   const config = JSON.parse(this.me.configJson)
    //   return config?.tutorial || false
    // }
    return false;
  }

  private set open(value: boolean) {
    this.guideState.open = value;

    (async () => this.updateConfig({
      ...JSON.parse(this.me.configJson),
      tutorial: value
    }))();
  }

  private async updateConfig(config: UserConfigModel) {
    try {
      await this.$apollo.mutate<{ user: schema.User }, schema.MutationMeUpdateArgs>({
        mutation: mutations.meUpdate,
        variables: {
          input: {
            displayName: this.me.displayName,
            configJson: JSON.stringify(config),
          },
        },
      });
      this.$apollo.queries.me.refresh();
    } catch (err) {
      console.error(err);
    }
  }
};
