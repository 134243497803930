



























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { proxies } from '@/store';
import { gql } from 'apollo-boost';
import { schema } from '@/gql';
import HeadingCard from '@/components/section/HeadingCard.vue';
import KnowledgeBreadcrumbs from '@/components/output/KnowledgeBreadcrumbs.vue';
import { Validate } from 'vuelidate-property-decorators';
import { required } from "vuelidate/lib/validators";

@Component({
  components: {
    HeadingCard,
    KnowledgeBreadcrumbs,
  },
})
export default class ComposeDrawer extends Vue {
  @Prop(String) orgUid!: string;
  private composeState = proxies.Compose;
  private snackbar = proxies.Snackbar;
  private loading: boolean = false;
  private saveTimeout: ReturnType<typeof setTimeout> | null = null;

  private get open(): boolean {
    return !!this.orgUid && !!this.composeState.open;
  }
  private set open(value: boolean) {
    this.composeState.open = value;
  }

  @Validate({ required })
  private title: string = "";

  @Validate({ required })
  private content: string = "";

  private get titleErrors() {
    const errors: string[] = [];
    if (!this.$v.title.$dirty) return errors;
    if (!this.$v.title.required) errors.push("Title is required.");
    return errors;
  }

  private get contentErrors() {
    const errors: string[] = [];
    if (!this.$v.content.$dirty) return errors;
    if (!this.$v.content.required) errors.push("Title is required.");
    return errors;
  }

  private save() {
    if (!this.saveTimeout && this.title && this.content) {
      this.saveTimeout = setTimeout(() => {
        localStorage.setItem(this.storageName(this.orgUid), JSON.stringify({
          title: this.title,
          content: this.content,
        }))
        this.saveTimeout = null;
      }, 5000)
    }
  }

  private async submit() {
    this.$v.$touch();
    if (this.loading || this.$v.$invalid) return;
    this.loading = true;

    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation composeDrawer($input: ArticlePublishInput!) {
            articlePublish(input: $input)
          }
        `,
        variables: {
          input: {
            orgUid: this.orgUid,
            questionText: this.title,
            answerText: this.content,
          } as schema.ArticlePublishInput,
        },
      });
      console.log("result", result)
      this.snackbar.showSuccess('Article successfully published!');
      this.open = false;
      this.title = "";
      this.content = "";
      this.$v.$reset();
      localStorage.removeItem(this.storageName(this.orgUid));
      this.$router.push({
        name: 'article',
        params: {orgUidOrDomain: this.orgUid, messageEid: result.data.articlePublish}
      })
    } catch (err) {
      console.error(err)
      this.snackbar.showWarning('There was an error creating the article');
    } finally {
      this.loading = false;
    }
  }

  @Watch('orgUid')
  private changeOrg(newOrgUid: string, oldOrgUid: string) {
    localStorage.setItem(this.storageName(oldOrgUid), JSON.stringify({
      title: this.title,
      content: this.content,
    }));
    
    const readVal = localStorage.getItem(this.storageName(newOrgUid));
    if (readVal) {
      const { title, content } = JSON.parse(readVal);
      this.title = title;
      this.content = content;
    } else {
      this.title = "";
      this.content = "";
    }
  }

  private storageName(orgUid: string): string {
    return `compose.draft.${orgUid}`
  }

};
