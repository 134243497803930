



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { schema } from '@/gql';
import { gql } from 'apollo-boost';
import { proxies } from '@/store';
import { mdiAccount } from '@mdi/js'

@Component({
  apollo: {
    authorAspectMember: {
      fetchPolicy: 'cache-first',
      query: gql`
        query aspectMember($memberEid: String!, $sourceUid: String!){
            aspectMember(memberEid: $memberEid, sourceUid: $sourceUid){
                id
                eid
                displayName
                profilePictureUrl
                sourceUid
            }
        }
      `,
      variables(): schema.QueryAspectMemberArgs {
        return {
          memberEid: this.memberEid,
          sourceUid: this.sourceUid,
        };
      },
      update(query: schema.Query): schema.AspectMember | null {
        return query.aspectMember || null;
      },
      skip() {
        return !this.sourceUid || !this.memberEid;
      },
    },
  },
})
export default class AspectUserAvatarTooltip extends Vue {
  @Prop(String) sourceUid!: string;
  @Prop(String) memberEid!: string;
  @Prop({ default: 48, type: [String, Number]}) private readonly size!: string | number;
  
  private theme = proxies.Theme;
  private authorAspectMember!: schema.AspectMember;
  private mdiAccount = mdiAccount;

  private get iconsize() {
    return parseInt(this.size.toString())*2/3;
  }
  private get hasUrl() {
    return !!this.imageUrl;
  }
  private get imageUrl() {
    return this.authorAspectMember?.profilePictureUrl;
  }
  private get tooltip() {
    return this.authorAspectMember?.displayName || 'Anonymous User';
  }
  private get loading() {
    return this.$apollo.queries.authorAspectMember.loading;
  }
}
