import { RouteConfig } from 'vue-router';

export default [
  /**
   * OAuth Standard Callback
   */
  {
    path: '/oauth/popup/callback',
    name: 'oauth-callback',
    component: (/* webpackChunkName: "oauth" */) => import('@/views/oauth/OAuthPopupCallback.vue'),
    meta: {
      title: 'Login',
      requireAuth: false,
    }
  },
  // /**
  //  * OAuth Account Link Callback
  //  */
  // {
  //   name: 'oauth-callback-account-link',
  //   path: '/oauth/account-link/popup/callback',
  //   component: (/* webpackChunkName: "oauth" */) => import('@/views/OAuthPopupCallbackAccountLinkSource.vue'),
  // },
  /**
   * OAuth Bot Install Callback
   */
  {
    name: 'oauth-callback-add-arkive',
    path: '/oauth/add-arkive/popup/callback',
    component: (/* webpackChunkName: "oauth" */) => import('@/views/oauth/OAuthPopupCallbackAddArkive.vue'),
  },
] as RouteConfig[];
