

















import { Vue, Component, Watch } from 'vue-property-decorator';
import AppBar from "@/components/layout/AppBar.vue"
import AppFooter from "@/components/layout/AppFooter.vue"
import Snackbar from '@/components/layout/Snackbar.vue';
import HistoryDrawer from '@/components/layout/HistoryDrawer.vue';
import ComposeDrawer from '@/components/layout/ComposeDrawer.vue';
import GuideDrawer from '@/components/layout/GuideDrawer.vue';
import { proxies } from '@/store';
import { queries, schema } from '@/gql';

@Component({
  components: {
    AppBar,
    AppFooter,
    Snackbar,
    ComposeDrawer,
    HistoryDrawer,
    GuideDrawer
  },
  apollo: {
    orgDisplayName: {
      query: queries.org,
      fetchPolicy: 'cache-first',
      skip() {
        return !this.orgUid;
      },
      variables(): schema.QueryOrgArgs {
        return {uid: this.orgUid};
      },
      update(query: schema.Query) {
        this.$nextTick(() => this.updateTitle());
        return query.org.displayName;
      },
    },
  },
})
export default class App extends Vue {
  private theme = proxies.Theme;
  private orgUidOrDomain: string | null = null;
  private orgUid: string | null = null;
  private orgDisplayName: string | null = null;

  @Watch('theme.darkMode')
  private useDarkMode(value: boolean) {
    this.$vuetify.theme.dark = value;
  }

  @Watch('$route.params.orgUidOrDomain')
  private watchRoute() {
    this.orgUidOrDomain = this.$route.params.orgUidOrDomain || null;
    // fetch subdomain here
    this.orgUid = this.orgUidOrDomain;
    this.updateTitle();
  }

  private updateTitle() {
    const metaTitle = this.$route.meta?.title || '';
    if (metaTitle && this.orgDisplayName){
      document.title = `${this.orgDisplayName} - ${metaTitle}`;
    } else if (this.orgDisplayName) {
      document.title = `Arkive.ai - ${this.orgDisplayName}`
    } else if (metaTitle) {
      document.title = `Arkive.ai - ${metaTitle}`;
    } else {
      document.title = `Arkive.ai`;
    }
  }

  private mounted() {
    this.useDarkMode(this.theme.darkMode);
    this.watchRoute()
  }
}
