
























import { Vue, Component, Prop } from 'vue-property-decorator';
import { proxies } from '@/store';

@Component
export default class HeadingCard extends Vue {
  @Prop({ default: 'primary' }) readonly barColor!: string;
  @Prop({ default: false }) readonly popup!: boolean;
  @Prop({ default: false }) readonly noMargin!: boolean;

  private theme = proxies.Theme;

  private get hasTitle() {
    return !!this.$slots.title;
  }
  private get hasActions() {
    return !!this.$slots.actions;
  }
};
