














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { schema } from '@/gql';
import AspectUserAvatarTooltip from '@/components/output/AspectUserAvatarTooltip.vue'
import DeltaTimeTooltip from '@/components/tooltip/DeltaTimeTooltip.vue';
// import copy from 'copy-to-clipboard';

@Component({
  components: {
    AspectUserAvatarTooltip,
    DeltaTimeTooltip,
  },
  apollo: {
  }
})
export default class MessageHistoryItem extends Vue {
  @Prop(Object) aspectMessage!: schema.AspectMessage;
  @Prop(Number) targetTimestamp!: number;
  @Prop(String) label!: string;
  @Prop(String) barColor!: string;

  // private get externalUrl() {
  //   return this.aspectMessage?.externalUrl || '#';
  // }

  // private copySourceUrl() {
  //   copy(this.aspectMessage.externalUrl);
  //   proxies.Snackbar.showSuccess('Copied to clipboard!');
  // }
}
